import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import MEDIA_QUERY from '../../../../constants/mediaQuery';
import Table, { ColumnsType } from 'antd/es/table';
import client from '../../../../api/axios';
import { useAtom } from 'jotai';
import { tokenAtom } from '../../../../stores';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

interface ContractDataType {
  name: string;
  digit: string;
  office_name: string;
  email: string;
  content: string;
  PIA: boolean;
}

const AdminContactPage = () => {
  const [userToken] = useAtom(tokenAtom);

  const navigate = useNavigate();

  const [contactList, setContactList] = useState<ContractDataType[] | undefined>([]);

  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [isRemoveButtonVisible, setIsRemoveButtonVisible] = useState(false);

  const columns: ColumnsType<ContractDataType> = [
    {
      title: '번호',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: '연락처',
      dataIndex: 'digit',
      key: 'digit',
      align: 'center',
    },
    {
      title: '회사명',
      key: 'office_name',
      dataIndex: 'office_name',
      align: 'center',
    },
    {
      title: '이메일',
      key: 'email',
      dataIndex: 'email',
      align: 'center',
    },
    {
      title: '제휴 문의 내용',
      key: 'content',
      dataIndex: 'content',
      align: 'center',
      render: (content) => {
        if (content === null) {
          return <span>-</span>;
        } else {
          return <ContentSpan>{content}</ContentSpan>;
        }
      },
    },
    {
      title: '',
      key: 'deleteButton',
      align: 'center',
      render: (_, record, index) => {
        if (isRemoveButtonVisible) {
          return <RemoveButton onClick={() => handleRemoveContract(index)}>삭제</RemoveButton>;
        } else {
          return null;
        }
      },
    },
  ];

  // Contact 전체 데이터 조회
  const handleQueryContact = async () => {
    try {
      const response = await client.get('api/v1/contacts/contract/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      setContactList(response.data.results.filter((item: any) => item.is_cancel === 'N'));
    } catch (error) {
      console.error(error);
    }
  };

  // Contact 개별 데이터 조회
  const handleQuerySingleContact = async (rowIndex: number) => {
    const contractId = rowIndex + 1;

    try {
      const response = await client.get(`api/v1/contacts/contract/${1}/`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      console.log('개별 response', response);
    } catch (error) {
      console.error(error);
    }
  };

  // 제휴 문의 내역 삭제 (DB에서는 안사라지고, 사용자 테이블에서만 보이지 않게)
  const handleRemoveContract = async (rowIndex: number) => {
    const contactId = rowIndex + 1;

    const data = {
      id: String(contactId),
    };

    try {
      await client.patch(`api/v1/contacts/contract/`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      setSelectedRowIndex(null);
      navigate('/ilsang-admin/contact/partnerships');

      message.success({ content: '제휴 문의 내역이 삭제되었습니다.' });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleQueryContact();
  }, []);

  return (
    <>
      <Helmet>
        <title>관리자 | 제휴 문의 — 눈부신 일상</title>
      </Helmet>

      <Container>
        <OuterWrapper>
          <InnerWrapper>
            <h2>제휴 문의 내역</h2>
            <DescriptionSpan>
              테이블의 각 행을 선택하시면, 자세한 정보를 보실 수 있는 페이지로 이동됩니다.
            </DescriptionSpan>

            <ContractTable
              columns={columns}
              dataSource={contactList}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    if (rowIndex !== undefined) {
                      navigate('/ilsang-admin/contact/partnerships/detail', { state: { contactData: record } });
                    }
                  },
                };
              }}
            />
          </InnerWrapper>
          <ButtonWrapper>
            <VisibleRemoveToggleButton onClick={() => setIsRemoveButtonVisible((prev) => !prev)}>
              문의 내역 삭제
            </VisibleRemoveToggleButton>
          </ButtonWrapper>
        </OuterWrapper>
      </Container>
    </>
  );
};

export default AdminContactPage;

const Container = styled.div`
  height: 100%;
  background-color: #fff;
`;

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${MEDIA_QUERY.lg} {
    margin-top: -3rem;
  }
`;

const ContractTable = styled(Table)`
  width: 100rem;
  margin-top: 2rem;
`;

const DescriptionSpan = styled.span`
  color: #000;
  font-family: ArgentumSansRegular;
  font-size: 1.3rem;
  margin-bottom: 1rem;
`;

const ContentSpan = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  max-width: 20rem;
  display: inline-block;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const VisibleRemoveToggleButton = styled.button`
  width: 10rem;
  height: 3rem;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  background-color: #ce2c30;
  opacity: 1;
  cursor: pointer;
`;

const RemoveButton = styled.button`
  width: 5rem;
  height: 3rem;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  background-color: #ce2c30;
  opacity: 1;
  cursor: pointer;
`;
