import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import client from '../../../api/axios';
import { useAtom } from 'jotai';
import { tokenAtom } from '../../../stores';
import { useEffect, useState } from 'react';
import { Table, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

interface ReservationDataType {
  name: string;
  digit: string;
  password: string;
  shooting_selection: string;
  office_selection: string;
  desired_date: string;
  content: string;
  PIA: boolean;
}

const AdminPartnershipsPage = () => {
  const [userToken] = useAtom(tokenAtom);

  const navigate = useNavigate();

  const [reservationList, setReservationList] = useState<ReservationDataType[] | undefined>([]);

  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [isRemoveButtonVisible, setIsRemoveButtonVisible] = useState(false);

  const columns: ColumnsType<ReservationDataType> = [
    {
      title: '번호',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: '연락처',
      dataIndex: 'digit',
      key: 'digit',
      align: 'center',
    },
    {
      title: '촬영 선택',
      key: 'shooting_selection',
      dataIndex: 'shooting_selection',
      align: 'center',
    },
    {
      title: '지점 선택',
      key: 'office_selection',
      dataIndex: 'office_selection',
      align: 'center',
    },
    {
      title: '희망 날짜',
      key: 'desired_date',
      dataIndex: 'desired_date',
      align: 'center',
      render: (date) => {
        return <span>{moment(date).format('YYYY-MM-DD')}</span>;
      },
    },
    {
      title: '문의 내용',
      key: 'content',
      dataIndex: 'content',
      width: '20rem',
      align: 'center',
      render: (content) => {
        if (content === null) {
          return <span>-</span>;
        } else {
          return <ContentSpan>{content}</ContentSpan>;
        }
      },
    },
    {
      title: '',
      key: 'deleteButton',
      align: 'center',
      render: (_, record, index) => {
        if (isRemoveButtonVisible) {
          return <RemoveButton onClick={() => handleRemoveReservation(index)}>삭제</RemoveButton>;
        } else {
          return null;
        }
      },
    },
  ];

  // Reservation 전체 데이터 조회
  const handleQueryReservation = async () => {
    try {
      const response = await client.get('api/v1/contacts/reservation/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      setReservationList(response.data.results.filter((item: any) => item.is_cancel === 'N'));
    } catch (error) {
      console.error(error);
    }
  };

  // Reservation 개별 데이터 조회
  const handleQuerySingleReservation = async (rowIndex: number) => {
    const reservationId = rowIndex + 1;

    try {
      const response = await client.get(`api/v1/contacts/reservation/${reservationId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  // Reservation 내역 삭제 (DB에서는 안사라지고, 사용자 테이블에서만 보이지 않게)
  const handleRemoveReservation = async (rowIndex: number) => {
    const reservationId = rowIndex + 1;

    const data = {
      id: String(reservationId),
    };

    try {
      await client.patch(`api/v1/contacts/reservation/`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${userToken}`,
        },
        withCredentials: true,
      });

      setSelectedRowIndex(null);
      message.success({ content: '예약 및 문의 내역이 삭제되었습니다.' });
      navigate('/ilsang-admin/contact/');
    } catch (error) {
      console.error(error);
    }
  };

  // 페이지 진입시 Reservation 전체 데이터 불러오기
  useEffect(() => {
    handleQueryReservation();
  }, []);

  return (
    <>
      <Helmet>
        <title>관리자 | 예약 및 문의 — 눈부신 일상</title>
      </Helmet>

      <Container>
        <OuterWrapper>
          <InnerWrapper>
            <h2>예약 및 문의 내역</h2>
            <DescriptionSpan>
              테이블의 각 행을 선택하시면, 자세한 정보를 보실 수 있는 페이지로 이동됩니다.
            </DescriptionSpan>

            <Reservationtable
              columns={columns}
              dataSource={reservationList}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    if (rowIndex !== undefined) {
                      navigate('/ilsang-admin/contact/detail', { state: { reservationData: record } });
                    }
                  },
                };
              }}
            />
          </InnerWrapper>
          <ButtonWrapper>
            <VisibleRemoveToggleButton onClick={() => setIsRemoveButtonVisible((prev) => !prev)}>
              문의 내역 삭제
            </VisibleRemoveToggleButton>
          </ButtonWrapper>
        </OuterWrapper>
      </Container>
    </>
  );
};

export default AdminPartnershipsPage;

const Container = styled.div`
  height: 100%;
  background-color: #fff;
`;

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'S-CoreDream-3Light';
  justify-content: center;
`;

const Reservationtable = styled(Table)`
  width: 100rem;
  margin-top: 2rem;
`;

const DescriptionSpan = styled.span`
  color: #000;
  font-family: ArgentumSansRegular;
  font-size: 1.3rem;
  margin-bottom: 1rem;
`;

const ContentSpan = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  max-width: 20rem;
  display: inline-block;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const VisibleRemoveToggleButton = styled.button`
  width: 10rem;
  height: 3rem;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  background-color: #ce2c30;
  opacity: 1;
  cursor: pointer;
`;

const RemoveButton = styled.button`
  width: 5rem;
  height: 3rem;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  background-color: #ce2c30;
  opacity: 1;
  cursor: pointer;
`;